<template>
<div id="userDetails" v-if="UserData != undefined">

<!-- notification -->
<v-snackbar v-model="notificationToggle" :color="notificationType" :timeout="5000" :top="true"> {{ notificationMsg }}
  <v-btn dark text @click="notificationToggle = false" > Close </v-btn>
</v-snackbar>

<div class="pa-5 secondary elevation-5 bg-accent-card">
    <p class="mt-5 display-1">User Details</p>
</div>

<!-- profile pic -->
<v-container class="py-5" fluid style="margin-top: -200px">
<v-card class="py-5 elevation-5">
    <v-btn color="red" absolute right dark fab @click="deleteDialog = true"><v-icon>mdi-delete</v-icon></v-btn>
<v-row>
<v-col cols="12" sm="12" md="4" class="d-flex align-center justify-center">
<div id="ProfileCard" class="pa-5">
    <div class="white pa-1 d-flex align-center justify-center elevation-5" style="border-radius: 300px; width: 170px; height: 170px">
        <img v-if="!UserData.photoURL" src="../../assets/images/profile-pic.svg" alt="Profile Pic" style="height: 150px; width: 150px; border-radius: 100px">
        <img v-else :src="UserData.photoURL" alt="Profile Pic" style="height: 150px;width: 150px; border-radius: 100px">
    </div>
</div>
</v-col>
<v-col cols="12" sm="12" md="8" class="d-flex align-center justify-center" style="flex-direction: column">
    <p class="headline text-center mt-5">{{UserData.displayName}}</p>       
    <p class="subtitle-2 text-center">{{UserData.email}}</p>    
    <p class="subtitle-2 text-center">Phone: {{UserData.phone}}</p>    
    <v-btn v-if="claim == 'Teacher'" @click="claimDialog = true" rounded class="primary elevation-10 text-center mb-0">Teacher</v-btn>    
    <v-btn v-else-if="claim == 'Admin'" @click="claimDialog = true" rounded class="primary elevation-10 text-center ma-0">Admin</v-btn>    
    <v-btn v-else rounded class="primary elevation-10 text-center ma-0">User</v-btn>    
</v-col>
</v-row>

</v-card>
</v-container>

<!-- delete modal -->
<v-dialog v-model="deleteDialog" persistent max-width="290" transition="slide-y-transition">
<v-card>
<v-card-title class="headline">Confirm Delete?</v-card-title>
<v-card-text>
    This action can't be undone!
</v-card-text>
<v-card-actions>
    <v-spacer></v-spacer>
    <v-btn color="primary" text @click="deleteDialog = false">Cancel</v-btn>
    <v-btn color="primary" text @click="DeleteUser()">Delete</v-btn>
</v-card-actions>
</v-card>
</v-dialog>
<!-- delete dialog ends -->

<!-- change claim -->
<v-dialog v-model="claimDialog" v-if="claim != ''" persistent max-width="290" transition="slide-y-transition">
<v-card>
<v-card-title class="headline" v-if="claim == 'Teacher'">Gift Power ?</v-card-title>
<v-card-title class="headline" v-else-if="claim == 'Admin'">Remove Power ?</v-card-title>
<v-card-text>
    Respected User Must Re-login!
</v-card-text>
<v-card-actions>
    <v-spacer></v-spacer>
    <v-btn color="primary" text @click="claimDialog = false">Cancel</v-btn>
    <v-btn color="primary" text v-if="claim == 'Teacher'" @click="changeClaim('Admin')">Yes</v-btn>
    <v-btn color="primary" text v-else-if="claim == 'Admin'" @click="changeClaim('Teacher')">Yes</v-btn>
</v-card-actions>
</v-card>
</v-dialog>

</div>
</template>

<script>
// imports
import firebase from 'firebase/app';
import "firebase/auth";
import "firebase/functions";

// scripts
export default {

    name: 'userDetails',

    data(){return{
        claim: '',deleteDialog: false,claimDialog: false,
        // app flags
        notificationToggle: false,notificationMsg: 'hai',notificationType: 'hui',
    }},

    mounted(){
        this.UserData != null ? this.getClaims(this.UserData.email) : null;
    },

    computed: {

        UserId(){         
          return this.$route.params.uid != null ? this.$route.params.uid : null;
        },

        UserData(){
            if(this.UserId != null) {
                let data =  this.$store.getters.GetUserWithID(this.UserId);
                if(data != undefined) { 
                    if(data.length != 0){return data[0]}else{this.$router.push("/users");}
                }else{this.$router.push("/users");}
            }else{this.$router.push("/users"); }             
        }
        
    },


    methods: {

        getClaims(emailVar){
            let claimFunction = firebase.functions().httpsCallable('getCustomClaim');
            claimFunction({email: emailVar}).then((succ) => {
                this.claim = succ.data;
            }).catch((err) => {console.log(err)});
        },

        async DeleteUser(){
            this.deleteDialog = false;
            // get image url
            let url
            if(this.UserData.photoURL){
                url = this.UserData.photoURL.toString();
            }
            // get document where user id equals selected one
            await firebase.firestore().collection("users")
            .where("uid", "==", this.UserId).get().then(
            (doc) => { if(doc.docs[0].id){
                // delete all datas(sorry for cramping it up)
                firebase.firestore().collection("users").doc(doc.docs[0].id).delete()
                .then(
                (result) => { 
                    
                    if(url){
                        firebase.storage().refFromURL(url).delete();
                    }

                    let deleteFunction = firebase.functions().httpsCallable('deleteUser');
                    deleteFunction(this.UserId).then((succ) => {
                            // close all dialogs 
                        this.$router.push("/users");
                    }).catch((err) => {console.log(err)});

                });}       
            }, (err) => { console.log(err);  }); 
        },

        async changeClaim(claimv){
            this.claimDialog = false;
            if(claimv == 'Teacher'){
                let TeacherCall =  firebase.functions().httpsCallable('addTeacherRole');
                TeacherCall({email: this.UserData.email}).then((per) => {
                    this.notificationService("Removed Power!", "success");
                    this.disbtn = false;this.getClaims(this.UserData.email);
                }).catch((err) => {console.log(err)});
            }else if(claimv == 'Admin'){       
                let AdminCall =  firebase.functions().httpsCallable('addAdminRole');
                AdminCall({email: this.UserData.email}).then((per) => {
                    this.notificationService("Gifted Power!", "success");
                    this.disbtn = false;this.getClaims(this.UserData.email);
                }).catch((err) => {console.log(err)});
            }
        },

        // notification service
        notificationService(msg, type){
            this.notificationType = type;
            this.notificationMsg = msg;
            this.notificationToggle = true;
        },


    }
}
</script>

<style scoped>

</style>